/* eslint-disable indent */
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Lead, Contact, Company, User, VoximplantCall } from "../types/bitrix";
import { Button } from "@mui/material";
import AudioPlayer from "../components/AudioPlayer";
import { environment, environmentSettings } from "../config";
import { addHours } from "date-fns";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const BX24: any;

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90, type: "number" },
  {
    field: "lead",
    headerName: "Prospecto",
    width: 250,
    valueGetter: ({ value }) =>
      value
        ? `${(value.TITLE && `(${value.TITLE}) `) || ""}${value.NAME || ""} ${
            value.LAST_NAME || ""
          }`
        : "",
    renderCell: ({ row }) =>
      row.lead ? (
        <Button
          onClick={() => {
            BX24.openPath(`/crm/lead/details/${row.lead.ID}/`);
          }}
        >
          {`${(row.lead.TITLE && `(${row.lead.TITLE}) `) || ""}${
            row.lead.NAME || ""
          } ${row.lead.LAST_NAME || ""}`}
        </Button>
      ) : (
        "-"
      ),
  },
  {
    field: "contact",
    headerName: "Contacto",
    width: 250,
    valueGetter: ({ value }) =>
      value ? `${value.NAME || ""} ${value.LAST_NAME || ""}` : "",
    renderCell: ({ row }) =>
      row.contact ? (
        <Button
          onClick={() => {
            BX24.openPath(`/crm/contact/details/${row.contact.ID}/`);
          }}
        >
          {`${row.contact.NAME || ""} ${row.contact.LAST_NAME || ""}`}
        </Button>
      ) : (
        "-"
      ),
  },
  {
    field: "company",
    headerName: "Compañía",
    width: 250,
    valueGetter: ({ value }) => (value ? value.TITLE : ""),
    renderCell: ({ row }) =>
      row.company ? (
        <Button
          onClick={() => {
            BX24.openPath(`/crm/company/details/${row.company.ID}/`);
          }}
        >
          {row.company.TITLE}
        </Button>
      ) : (
        "-"
      ),
  },
  {
    field: "user",
    headerName: "Operador",
    width: 250,
    valueGetter: ({ value }) =>
      value ? `${value.NAME || ""} ${value.LAST_NAME || ""}` : "",
    renderCell: ({ row }) =>
      row.user ? (
        <Button
          onClick={() => {
            BX24.openPath(`/company/personal/user/${row.user.ID}/`);
          }}
        >
          {`${row.user.NAME || ""} ${row.user.LAST_NAME || ""}`}
        </Button>
      ) : (
        "-"
      ),
  },
  {
    field: "answer1",
    headerName: "Cal. Respuesta",
    width: 150,
    type: "number",
  },
  {
    field: "answer2",
    headerName: "Cal. de Atención",
    width: 150,
    type: "number",
  },
  {
    field: "calificationReason",
    headerName: "Motivo Cal.",
    width: 250,
    renderCell: (params) => (
      <div style={{ width: "100%", overflowWrap: "anywhere", padding: 8 }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
  {
    field: "phone",
    headerName: "Teléfono",
    width: 200,
  },
  {
    field: "mainCategory",
    headerName: "Rubro Principal",
    width: 200,
  },
  {
    field: "secondaryCategory",
    headerName: "Rubro Secundario",
    width: 200,
  },
  {
    field: "record",
    headerName: "Grabación",
    width: 310,
    valueGetter: ({ value }) => (value ? value : ""),
    renderCell: ({ row }) =>
      row.record ? <AudioPlayer src={row.record} /> : "-",
  },
  {
    field: "filledDate",
    headerName: "Completado en",
    type: "dateTime",
    width: 200,
    valueGetter: ({ value }) => {
      if (!value) {
        return null;
      }
      const date = value.split(" ")[0];
      const time = value.split(" ")[1];

      const year = Number(date.split(".")[2]);
      const month = Number(date.split(".")[1]) - 1;
      const day = Number(date.split(".")[0]);
      const hours = Number(time.split(":")[0]);
      const minutes = Number(time.split(":")[1]);
      const seconds = Number(time.split(":")[2]);
      return addHours(new Date(year, month, day, hours, minutes, seconds), -6);
    },
  },
  {
    field: "createdDate",
    headerName: "Creado en",
    type: "dateTime",
    width: 200,
    valueGetter: ({ value }) => {
      if (!value) {
        return null;
      }
      const date = value.split(" ")[0];
      const time = value.split(" ")[1];

      const year = Number(date.split(".")[2]);
      const month = Number(date.split(".")[1]) - 1;
      const day = Number(date.split(".")[0]);
      const hours = Number(time.split(":")[0]);
      const minutes = Number(time.split(":")[1]);
      const seconds = Number(time.split(":")[2]);
      return addHours(new Date(year, month, day, hours, minutes, seconds), -6);
    },
  },
];

interface ReportTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  complementaryData: {
    leads: Lead[];
    contacts: Contact[];
    companies: Company[];
    users: User[];
    calls: VoximplantCall[];
  };
}

export default function ReportTable({
  data,
  complementaryData,
}: ReportTableProps) {
  return (
    <Box sx={{ minHeight: 400, width: "100%" }}>
      <DataGrid
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows={data.map((survey: any) => ({
          id: survey.ID,
          email:
            survey[environmentSettings[environment].EMAIL] &&
            Object.values(survey[environmentSettings[environment].EMAIL])[0],
          lead:
            survey[environmentSettings[environment].LEAD] &&
            complementaryData.leads.find(
              (l) =>
                l.ID ===
                Object.values(survey[environmentSettings[environment].LEAD])[0]
            ),
          contact:
            survey[environmentSettings[environment].CONTACT] &&
            complementaryData.contacts.find(
              (l) =>
                l.ID ===
                Object.values(
                  survey[environmentSettings[environment].CONTACT]
                )[0]
            ),
          company:
            survey[environmentSettings[environment].COMPANY] &&
            complementaryData.companies.find(
              (l) =>
                l.ID ===
                Object.values(
                  survey[environmentSettings[environment].COMPANY]
                )[0]
            ),
          user:
            survey[environmentSettings[environment].OPERATOR] &&
            complementaryData.users.find(
              (l) =>
                l.ID ===
                Object.values(
                  survey[environmentSettings[environment].OPERATOR]
                )[0]
            ),
          phone:
            survey[environmentSettings[environment].PHONE] &&
            Object.values(survey[environmentSettings[environment].PHONE])[0],
          answer1:
            survey[environmentSettings[environment].ANSWER_1] &&
            Object.values(survey[environmentSettings[environment].ANSWER_1])[0],
          answer2:
            survey[environmentSettings[environment].ANSWER_2] &&
            Object.values(survey[environmentSettings[environment].ANSWER_2])[0],
          calificationReason:
            survey[environmentSettings[environment].CALIFICATION_REASON] &&
            Object.values(
              survey[environmentSettings[environment].CALIFICATION_REASON]
            )[0],
          filledDate:
            survey[environmentSettings[environment].FILLED_DATE] &&
            Object.values(
              survey[environmentSettings[environment].FILLED_DATE]
            )[0],
          createdDate: survey.DATE_CREATE,
          mainCategory:
            survey[environmentSettings[environment].MAIN_CATEGORY] &&
            Object.values(
              survey[environmentSettings[environment].MAIN_CATEGORY]
            )[0],
          secondaryCategory:
            survey[environmentSettings[environment].SECONDARY_CATEGORY] &&
            Object.values(
              survey[environmentSettings[environment].SECONDARY_CATEGORY]
            )[0],
          record:
            survey[environmentSettings[environment].CALL_ID] &&
            complementaryData.calls.find(
              (l) =>
                l.CALL_ID ===
                Object.values(
                  survey[environmentSettings[environment].CALL_ID]
                )[0]
            )?.CALL_RECORD_URL,
        }))}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        slots={{ toolbar: GridToolbar }}
        sx={{ maxWidth: 1000 }}
        getRowHeight={() => "auto"}
      />
    </Box>
  );
}
