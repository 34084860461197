import React from "react";

interface AudioPlayerProps {
  src: string;
}

export default function AudioPlayer({ src }: AudioPlayerProps) {
  return (
    <div style={{ padding: 6 }}>
      <audio src={src} controls autoPlay={false} loop={false}>
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}
