import {
  Container,
  Stack,
  Card,
  Typography,
  Grid,
  TextField,
  FormLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import ReportTable from "./ReportTable";
import ReportRatesChart from "./ReportRatesChart";
import axios from "axios";
import { environment, environmentSettings } from "../config";
import { Company, Contact, Lead, User, VoximplantCall } from "../types/bitrix";
import {
  listCallsByIds,
  listCompaniesByIds,
  listContactsByIds,
  listLeadsByIds,
  listUsersByIds,
} from "../api/bitrix";
import { addDays } from "date-fns";

export default function ReportPage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({
    from: "",
    to: "",
  });
  const [complementaryData, setComplementaryData] = useState<{
    leads: Lead[];
    contacts: Contact[];
    companies: Company[];
    users: User[];
    calls: VoximplantCall[];
  }>({
    leads: [],
    contacts: [],
    companies: [],
    users: [],
    calls: [],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValues({
      ...filterValues,
      [(event.target as HTMLInputElement).name]: (
        event.target as HTMLInputElement
      ).value,
    });
  };

  const generateReport = async () => {
    setLoading(true);

    if (filterValues.from && filterValues.to) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let surveyList: any[] = [];
      let next = 0;
      do {
        const listRepsonse = await axios.get(
          `${
            process.env.REACT_APP_BITRIX_WEBHOOK
          }/lists.element.get?IBLOCK_TYPE_ID=lists&IBLOCK_ID=${
            environmentSettings[environment].IBLOCK_ID
          }&FILTER[>%3DDATE_CREATE]=${new Date(
            filterValues.from
          ).toISOString()}&FILTER[<%3DDATE_CREATE]=${addDays(
            new Date(filterValues.to),
            1
          ).toISOString()}&start=${next}`
        );

        surveyList = surveyList.concat(listRepsonse.data.result);
        next = listRepsonse.data.next;
      } while (Number(next));

      const leadIds: string[] = surveyList.reduce(
        (prev: string[], curr) =>
          curr[environmentSettings[environment].LEAD] &&
          !prev.includes(
            Object.values(
              curr[environmentSettings[environment].LEAD]
            )[0] as string
          )
            ? [
              ...prev,
              Object.values(curr[environmentSettings[environment].LEAD])[0],
            ]
            : prev,
        []
      );
      const contactIds: string[] = surveyList.reduce(
        (prev: string[], curr) =>
          curr[environmentSettings[environment].CONTACT] &&
          !prev.includes(
            Object.values(
              curr[environmentSettings[environment].CONTACT]
            )[0] as string
          )
            ? [
              ...prev,
              Object.values(
                curr[environmentSettings[environment].CONTACT]
              )[0],
            ]
            : prev,
        []
      );
      const companyIds: string[] = surveyList.reduce(
        (prev: string[], curr) =>
          curr[environmentSettings[environment].COMPANY] &&
          !prev.includes(
            Object.values(
              curr[environmentSettings[environment].COMPANY]
            )[0] as string
          )
            ? [
              ...prev,
              Object.values(
                curr[environmentSettings[environment].COMPANY]
              )[0],
            ]
            : prev,
        []
      );
      const userIds: string[] = surveyList.reduce(
        (prev: string[], curr) =>
          curr[environmentSettings[environment].OPERATOR] &&
          !prev.includes(
            Object.values(
              curr[environmentSettings[environment].OPERATOR]
            )[0] as string
          )
            ? [
              ...prev,
              Object.values(
                curr[environmentSettings[environment].OPERATOR]
              )[0],
            ]
            : prev,
        []
      );
      const callIds: string[] = surveyList.reduce(
        (prev: string[], curr) =>
          curr[environmentSettings[environment].CALL_ID] &&
          !prev.includes(
            Object.values(
              curr[environmentSettings[environment].CALL_ID]
            )[0] as string
          )
            ? [
              ...prev,
              Object.values(
                curr[environmentSettings[environment].CALL_ID]
              )[0],
            ]
            : prev,
        []
      );

      setComplementaryData({
        leads: leadIds.length ? await listLeadsByIds(leadIds) : [],
        contacts: contactIds.length ? await listContactsByIds(contactIds) : [],
        companies: companyIds.length
          ? await listCompaniesByIds(companyIds)
          : [],
        users: userIds.length ? await listUsersByIds(userIds) : [],
        calls: callIds.length ? await listCallsByIds(callIds) : [],
      });

      setData(surveyList);
      setLoading(false);
    } else {
      alert("Por favor, selecciona las fechas del reporte a generar");
      setLoading(false);
    }
  };

  return (
    <Container>
      <Stack alignItems="center">
        <Card
          className="survey-container"
          variant="outlined"
          sx={{ padding: 3 }}
        >
          <Stack spacing={2}>
            <img
              src="./ceg-logo.svg"
              height={45}
              style={{ marginBottom: 16 }}
            />
            <Typography variant="h4">Reporte de encuestas</Typography>
            <Typography variant="body1">
              Selecciona un rango de fechas y presiona el botón
              &lsquo;Generar&lsquo; para ver el reporte.{" "}
            </Typography>

            <Card sx={{ padding: 3 }}>
              <Stack direction="row" spacing={3}>
                <Stack>
                  <FormLabel id="from">Desde</FormLabel>
                  <TextField
                    type="date"
                    onChange={handleChange}
                    name="from"
                    value={filterValues.from}
                  />
                </Stack>
                <Stack>
                  <FormLabel id="to">Hasta</FormLabel>
                  <TextField
                    type="date"
                    onChange={handleChange}
                    name="to"
                    value={filterValues.to}
                  />
                </Stack>
                <Button
                  color="success"
                  onClick={() => generateReport()}
                  disabled={loading}
                >
                  Generar
                </Button>
              </Stack>
            </Card>

            {data && !loading && (
              <Grid container spacing={2}>
                <Grid item sm={12} mt={2}>
                  <Typography variant="h5">Reporte Gráfico general</Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                  <ReportRatesChart
                    title="Calificaciones de respuesta"
                    data={data}
                    property={
                      environmentSettings[environment].ANSWER_1.split("_")[1]
                    }
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <ReportRatesChart
                    title="Calificaciones de atención"
                    data={data}
                    property={
                      environmentSettings[environment].ANSWER_2.split("_")[1]
                    }
                  />
                </Grid>
                <Grid item sm={12} mt={2}>
                  <Typography variant="h5">Tabla dinámica</Typography>
                </Grid>
                <Grid item sm={12} md={12}>
                  <ReportTable
                    data={data}
                    complementaryData={complementaryData}
                  />
                </Grid>
              </Grid>
            )}

            {loading && (
              <Stack alignItems="center" p={4}>
                <CircularProgress color="success" />
              </Stack>
            )}
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
