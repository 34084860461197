import React, { useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { environment, environmentSettings } from "../config";
import useResponsive from "../hooks/useResponsive";

interface SurveyFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  survey: any;
  setSurvey: React.Dispatch<React.SetStateAction<undefined>>;
}

export default function SurveyForm({ survey, setSurvey }: SurveyFormProps) {
  const isMobile = useResponsive("down", "sm");
  const [formValues, setFormValues] = useState({
    question1: "",
    question2: "",
    reason: "",
  });

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormValues({
      ...formValues,
      [(event.target as HTMLInputElement).name]: (
        event.target as HTMLInputElement
      ).value,
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(survey);
    console.log(formValues);

    axios
      .post(`${process.env.REACT_APP_BITRIX_WEBHOOK}/lists.element.update`, {
        IBLOCK_TYPE_ID: "lists",
        IBLOCK_ID: environmentSettings[environment].IBLOCK_ID,
        ELEMENT_ID: survey.ID,
        FIELDS: {
          ...survey,
          [environmentSettings[environment].ANSWER_1]: formValues.question1,
          [environmentSettings[environment].ANSWER_2]: formValues.question2,
          [environmentSettings[environment].CALIFICATION_REASON]:
            formValues.reason,
          [environmentSettings[environment].FILLED_DATE]:
            new Date().toISOString(),
        },
      })
      .then(() => setSurvey(undefined))
      .catch(() => alert("Hubo un error al enviar el formulario."));
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={4}>
        <Typography variant="h6" style={{ color: "#555555" }}>
          Teniendo en cuenta que 1 es mala y 5 es excelente
        </Typography>
        <FormControl required>
          <p className="input-label">¿Cómo calificas la respuesta obtenida?</p>
          <RadioGroup
            row={!isMobile}
            aria-labelledby="question1"
            name="question1"
            onChange={handleChange}
            value={formValues.question1}
            style={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <p className="input-label">Muy malo</p>
            <FormControlLabel
              value="1"
              control={<Radio size="small" />}
              label="1"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <FormControlLabel
              value="2"
              control={<Radio size="small" />}
              label="2"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <FormControlLabel
              value="3"
              control={<Radio size="small" />}
              label="3"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <FormControlLabel
              value="4"
              control={<Radio size="small" />}
              label="4"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <FormControlLabel
              value="5"
              control={<Radio size="small" required />}
              label="5"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <p className="input-label">Excelente</p>
          </RadioGroup>
        </FormControl>

        <FormControl required>
          <p className="input-label">¿Cómo calificas la atención recibida?</p>
          <RadioGroup
            row={!isMobile}
            aria-labelledby="question2"
            name="question2"
            onChange={handleChange}
            value={formValues.question2}
            style={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <p className="input-label">Muy malo</p>
            <FormControlLabel
              value="1"
              control={<Radio size="small" />}
              label="1"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <FormControlLabel
              value="2"
              control={<Radio size="small" />}
              label="2"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <FormControlLabel
              value="3"
              control={<Radio size="small" />}
              label="3"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <FormControlLabel
              value="4"
              control={<Radio size="small" />}
              label="4"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <FormControlLabel
              value="5"
              control={<Radio size="small" required />}
              label="5"
              labelPlacement={isMobile ? "end" : "top"}
            />
            <p className="input-label">Excelente</p>
          </RadioGroup>
        </FormControl>

        <p className="input-label">
          Aquí puedes contarnos por qué motivo calificaste con esa puntuación:
        </p>
        <textarea
          name="reason"
          rows={4}
          placeholder="Déjanos tus comentarios..."
          style={{ width: "94%" }}
          onChange={handleChange}
          value={formValues.reason}
        ></textarea>
        <button type="submit" style={{ marginTop: "32px", width: 180 }}>
          Enviar mi calificación
        </button>
      </Stack>
    </form>
  );
}
