export const environment = process.env.REACT_APP_BITRIX_ENV as "DEMO" | "PROD";

export const environmentSettings = {
  DEMO: {
    IBLOCK_ID: 48,
    FILLED_DATE: "PROPERTY_212",
    ANSWER_1: "PROPERTY_206",
    ANSWER_2: "PROPERTY_208",
    CALIFICATION_REASON: "PROPERTY_210",
    LEAD: "PROPERTY_216",
    CONTACT: "PROPERTY_218",
    COMPANY: "PROPERTY_220",
    OPERATOR: "PROPERTY_234",
    CALL_ID: "PROPERTY_232",
    EMAIL: "PROPERTY_222",
    PHONE: "PROPERTY_224",
    MAIN_CATEGORY: "PROPERTY_226",
    SECONDARY_CATEGORY: "PROPERTY_228",
  },
  PROD: {
    IBLOCK_ID: 39,
    FILLED_DATE: "PROPERTY_107",
    ANSWER_1: "PROPERTY_101",
    ANSWER_2: "PROPERTY_103",
    CALIFICATION_REASON: "PROPERTY_105",
    LEAD: "PROPERTY_109",
    CONTACT: "PROPERTY_111",
    COMPANY: "PROPERTY_113",
    OPERATOR: "PROPERTY_125",
    CALL_ID: "PROPERTY_123",
    EMAIL: "PROPERTY_115",
    PHONE: "PROPERTY_117",
    MAIN_CATEGORY: "PROPERTY_119",
    SECONDARY_CATEGORY: "PROPERTY_121",
  },
};
