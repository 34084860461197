import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Card, Typography } from "@mui/material";

interface ReportRatesChartProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  property: string;
  title: string;
}

export default function ReportRatesChart({
  data,
  property,
  title,
}: ReportRatesChartProps) {
  const values = {
    calification1: data.reduce(
      (prev, curr) =>
        curr[`PROPERTY_${property}`] &&
        Object.values(curr[`PROPERTY_${property}`])[0] == 1
          ? prev + 1
          : prev,
      0
    ),
    calification2: data.reduce(
      (prev, curr) =>
        curr[`PROPERTY_${property}`] &&
        Object.values(curr[`PROPERTY_${property}`])[0] == 2
          ? prev + 1
          : prev,
      0
    ),
    calification3: data.reduce(
      (prev, curr) =>
        curr[`PROPERTY_${property}`] &&
        Object.values(curr[`PROPERTY_${property}`])[0] == 3
          ? prev + 1
          : prev,
      0
    ),
    calification4: data.reduce(
      (prev, curr) =>
        curr[`PROPERTY_${property}`] &&
        Object.values(curr[`PROPERTY_${property}`])[0] == 4
          ? prev + 1
          : prev,
      0
    ),
    calification5: data.reduce(
      (prev, curr) =>
        curr[`PROPERTY_${property}`] &&
        Object.values(curr[`PROPERTY_${property}`])[0] == 5
          ? prev + 1
          : prev,
      0
    ),
  };

  return (
    <Card sx={{ padding: 2 }}>
      <Typography mb={2}>{title}</Typography>
      <PieChart
        series={[
          {
            data: [
              {
                id: 1,
                value: values.calification1,
                label: `Cal. 1 (${values.calification1})`,
              },
              {
                id: 2,
                value: values.calification2,
                label: `Cal. 2 (${values.calification2})`,
              },
              {
                id: 3,
                value: values.calification3,
                label: `Cal. 3 (${values.calification3})`,
              },
              {
                id: 4,
                value: values.calification4,
                label: `Cal. 4 (${values.calification4})`,
              },
              {
                id: 5,
                value: values.calification5,
                label: `Cal. 5 (${values.calification5})`,
              },
            ],
          },
        ]}
        width={400}
        height={200}
      />
    </Card>
  );
}
