import axios from "axios";
import { Company, Contact, Lead, User, VoximplantCall } from "../types/bitrix";

export const listLeadsByIds = async (leadIds: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rowList: Lead[] = [];
  let next = 0;
  do {
    const listRepsonse = await axios.post(
      `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.lead.list?start=${next}`,
      {
        SELECT: ["ID", "TITLE", "NAME", "LAST_NAME"],
        FILTER: {
          ID: leadIds,
        },
      }
    );

    rowList = rowList.concat(listRepsonse.data.result);
    next = listRepsonse.data.next;
  } while (Number(next));

  return rowList;
};
export const listContactsByIds = async (contactIds: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rowList: Contact[] = [];
  let next = 0;
  do {
    const listRepsonse = await axios.post(
      `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.contact.list?start=${next}`,
      {
        SELECT: ["ID", "NAME", "LAST_NAME"],
        FILTER: {
          ID: contactIds,
        },
      }
    );

    rowList = rowList.concat(listRepsonse.data.result);
    next = listRepsonse.data.next;
  } while (Number(next));

  return rowList;
};

export const listCompaniesByIds = async (companyIds: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rowList: Company[] = [];
  let next = 0;
  do {
    const listRepsonse = await axios.post(
      `${process.env.REACT_APP_BITRIX_WEBHOOK}/crm.company.list?start=${next}`,
      {
        SELECT: ["ID", "TITLE"],
        FILTER: {
          ID: companyIds,
        },
      }
    );

    rowList = rowList.concat(listRepsonse.data.result);
    next = listRepsonse.data.next;
  } while (Number(next));

  return rowList;
};

export const listUsersByIds = async (userIds: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rowList: User[] = [];
  let next = 0;
  do {
    const listRepsonse = await axios.post(
      `${process.env.REACT_APP_BITRIX_WEBHOOK}/user.search?start=${next}`,
      {
        SELECT: ["ID", "NAME", "LAST_NAME"],
        FILTER: {
          ID: userIds,
        },
      }
    );

    rowList = rowList.concat(listRepsonse.data.result);
    next = listRepsonse.data.next;
  } while (Number(next));

  return rowList;
};

export const listCallsByIds = async (callIds: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rowList: VoximplantCall[] = [];
  let next = 0;
  do {
    const listRepsonse = await axios.post(
      `${process.env.REACT_APP_BITRIX_WEBHOOK}/voximplant.statistic.get?start=${next}`,
      {
        SELECT: ["CALl_ID", "CALL_RECORD_URL"],
        FILTER: {
          CALL_ID: callIds,
        },
      }
    );

    rowList = rowList.concat(listRepsonse.data.result);
    next = listRepsonse.data.next;
  } while (Number(next));

  return rowList;
};
