import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SurveyPage.css";
import { Card, Container, Stack, Typography } from "@mui/material";
import SurveyForm from "./SurveyForm";
import { useSearchParams } from "react-router-dom";
import { environment, environmentSettings } from "../config";

export default function SurveyPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [survey, setSurvey] = useState();

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_BITRIX_WEBHOOK
        }/lists.element.get?IBLOCK_TYPE_ID=lists&IBLOCK_ID=${
          environmentSettings[environment].IBLOCK_ID
        }&ELEMENT_ID=${searchParams.get("id")}`
      )
      .then((response) => {
        if (response.data.result.length) {
          setSurvey(response.data.result[0]);
          searchParams.delete("id");
          setSearchParams(searchParams);
        }
      });
  }, []);
  return (
    <Container>
      <Stack alignItems="center" spacing={4}>
        <img src="./ceg-logo.svg" height={45} style={{ marginTop: 32 }} />
        <Card
          className="survey-container"
          variant="outlined"
          sx={{ padding: 3, maxWidth: 550 }}
        >
          {survey && !survey[environmentSettings[environment].FILLED_DATE] ? (
            <SurveyForm survey={survey} setSurvey={setSurvey} />
          ) : survey && survey[environmentSettings[environment].FILLED_DATE] ? (
            <Typography style={{ padding: "100px 30px" }}>
              Ya registramos tu respuesta, te agradecemos por participar.
            </Typography>
          ) : (
            <Typography style={{ padding: "100px 30px" }}>
              Ya registramos tu respuesta, te agradecemos por participar.
            </Typography>
          )}
        </Card>
        <a href="https://comprandoengrupo.com">
          <img
            src="https://mcusercontent.com/097b6b457b981c22fbaff6205/images/028c1a08-dfdf-9c5f-29ab-262bf8feee4f.png"
            alt=""
          />
        </a>
        <p className="footer-text">
          Comprando en Grupo — Todos los derechos reservados.
        </p>
        <Stack direction="row" spacing={1}>
          <a
            href="https://www.facebook.com/Comprandoengrupo.net/"
            style={{ textDecoration: "none" }}
          >
            <img
              src="https://mcusercontent.com/097b6b457b981c22fbaff6205/images/edfbe97c-27dc-7acf-d69b-22a2f6bee9b3.png"
              alt=""
              style={{ paddingLeft: "5px" }}
            />
          </a>
          <a
            href="https://www.instagram.com/comprandoengrupo/?hl=es-la"
            style={{ textDecoration: "none" }}
          >
            <img
              src="https://mcusercontent.com/097b6b457b981c22fbaff6205/images/7d3f7203-de82-10a8-3f72-d5c3e03fdc06.png"
              alt=""
              style={{ paddingLeft: "5px" }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/comprandoengrupo.net/mycompany/"
            style={{ textDecoration: "none" }}
          >
            <img
              src="https://mcusercontent.com/097b6b457b981c22fbaff6205/images/74653922-8fc7-719c-ca4d-9d99b9e06117.png"
              alt=""
              style={{ paddingLeft: "5px" }}
            />
          </a>
          <a
            href="https://twitter.com/ceg_argentina"
            style={{ textDecoration: "none" }}
          >
            <img
              src="https://mcusercontent.com/097b6b457b981c22fbaff6205/images/02beeaa0-3bd6-928e-8bdf-4fe07c24e1c7.png"
              alt=""
              style={{ paddingLeft: "5px" }}
            />
          </a>
        </Stack>
        <img
          src="https://mcusercontent.com/097b6b457b981c22fbaff6205/images/7d569e16-1503-b8f8-ebb9-141cc7250284.png"
          style={{ paddingTop: "16px", marginBottom: 32 }}
        />
      </Stack>
    </Container>
  );
}
